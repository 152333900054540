<template>
  <modal v-if="jahreslosData">
    <template #link="{openModal}">
      <a
        href="#"
        class="tw-cursor-pointer no-underline tw-flex tw-items-center hover:tw-text-red-500 focus:tw-text-red-500"
        @click.prevent="openModal"
      >
        <span
          class="tw-icon tw-icon-weiter-left-bold tw-transform tw-rotate-180 tw-text-xl"
        ></span>
        <span class="link-text">
          {{ linkText }}
        </span>
      </a>
    </template>
    <template #default="{closeModal}">
      <h2 class="tw-text-2xl tw-grey-800 font-bold">
        {{ headline }}
      </h2>

      <ul class="tw-mt-2 tw-mb-4">
        <li v-for="lotProperty in lotProperties" :key="lotProperty.value">
          <h3 class="tw-text-xl tw-mt-3 font-bold tw-text-red-500">
            {{ lotProperty.modal.headline }}
          </h3>
          <img
            class="tw-mt-2"
            :src="`https://www.aktion-mensch.de${lotProperty.modal.imageUrl}`"
          />
          <p class="tw-mt-2" v-html="lotProperty.modal.content" />
          <btn
            class="tw-mt-2"
            secondary
            @click="
              $emit('change-lot-format', lotProperty.value);
              closeModal();
            "
          >
            {{ lotProperty.modal.buttonText }}
          </btn>
        </li>
      </ul>
    </template>
  </modal>
</template>

<script>
export default {
  props: {
    lotProperties: Array,
    linkText: String,
    headline: String
  }
};
</script>

<style lang="scss" scoped></style>
